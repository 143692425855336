// CustomButton.js
import React from 'react';
import './CustomButton.css'; // Import your button styles
import { Link } from 'react-router-dom';

const CustomButton = ({ className, children, to, onClick }) => {
  if (to) {
    return (
      <Link to={to} className={`custom-button ${className}`}>
        {children}
      </Link>
    );
  } else {
    return (
      <button className={`custom-button ${className}`} onClick={onClick}>
        {children}
      </button>
    );
  }
};

export default CustomButton;
