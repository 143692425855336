// HorizontalMenu.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';

function NavBar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(1); // State to track selected menu item

  const handleMenuItemClick = (menuIndex) => {
    setSelectedMenuItem(menuIndex);
    setIsDropdownOpen(false); // Close dropdown when a menu item is clicked
  };

  return (
    <div>
      <div className="NavBar">
        <Link
          to="/Home"
          className={`menu-item ${selectedMenuItem === 0 ? 'menu-itemSelected' : ''}`}
          onClick={() => handleMenuItemClick(0)}
        >
          Home
        </Link>
        <Link
          to="/Menu"
          className={`menu-item ${selectedMenuItem === 1 ? 'menu-itemSelected' : ''}`}
          onClick={() => handleMenuItemClick(1)}
        >
          Menu
        </Link>
        <Link
          to="/TruckLocator"
          className={`menu-item ${selectedMenuItem === 2 ? 'menu-itemSelected' : ''}`}
          onClick={() => handleMenuItemClick(2)}
        >
          Truck Locator
        </Link>
        <Link
          to="/Contact"
          className={`menu-item ${selectedMenuItem === 3 ? 'menu-itemSelected' : ''}`}
          onClick={() => handleMenuItemClick(3)}
        >
          Contact
        </Link>
      </div>
    </div>
  );
}

export default NavBar;
