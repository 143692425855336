import "./Beverage.css";

function Beverage() {
  const coldBeverage = [
    { name: "Pepsi/Coke/Sprite", price: "$1.99" },
    { name: "Limca/Thumpsup", price: "$2.49" },
    { name: "Water", price: "$1.00" },
  ];
  const hotBeverage = [
    { name: "Tea(4 OZ)", price: "$1.00" },
    { name: "Tea(8 OZ)", price: "$1.59" },
    { name: "Bru Coffee(8 OZ)", price: "$2.49" },
    { name: "Boost", price: "$2.49" },
    { name: "Horlicks", price: "$2.49" },
  ];
  const desserts = [
    { name: "Mango Lassi", price: "$2.99" },
    { name: "Pista Kulfi", price: "$2.49" },
    { name: "Mango Kulfi", price: "$2.49" },
  ];

  return (
    <div className="menu">
      <div className="menu-section">
        <p className="item-heading">Hot Beverages</p>
        <ul>
          {hotBeverage.map((item, index) => (
            <li key={index}>
              <span className="item-name">{item.name}</span>
              <span className="dotted-line"></span>
              <span className="item-price">{item.price}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="menu-section">
        <p className="item-heading">cold Beverages</p>
        <ul>
          {coldBeverage.map((item, index) => (
            <li key={index}>
              <span className="item-name">{item.name}</span>
              <span className="dotted-line"></span>
              <span className="item-price">{item.price}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="menu-section">
        <p className="item-heading">Desserts</p>
        <ul>
          {desserts.map((item, index) => (
            <li key={index}>
              <span className="item-name">{item.name}</span>
              <span className="dotted-line"></span>
              <span className="item-price">{item.price}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Beverage;
