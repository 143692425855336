import "./FooterBody.css";
import { openingHours } from "../../constants";
import RestaurantIcon from '@mui/icons-material/Restaurant';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CampaignIcon from '@mui/icons-material/Campaign';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupsIcon from '@mui/icons-material/Groups';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';

function FooterBody() {
    const year = new Date().getFullYear();
    const phoneNumber = "+14794129916";  
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent("Hello, I would like to know more about your services")}`;
    return (
        <div className="footerBody">
            <div className="textArea">
                <div className="about">
                    <div className="heading">
                        <RestaurantIcon sx={{ color: "#FFD700", height: '30px' }} />
                        <p className="headingText"> About Desi Bites</p>
                    </div>
                    <div>
                        <p className="aboutText">
                        Desi Bites rolled onto the streets in 2024, bringing a vibrant taste of India to the neighborhood. With every bite, we serve up the rich, flavorful comfort of Indian cuisine, transforming traditional street food into something truly unforgettable.
                        </p>
                    </div>
                </div>
                <div className="hours">
                    <div className="heading">
                        <AccessTimeIcon sx={{ color: "#FFD700", height: '30px' }} />
                        <p className="headingText">Opening Hours</p>
                    </div>
                    <div>
                        {openingHours.map((day, index) => (
                            <div>
                                <div className="openHours">
                                    <p>{day.day}</p>
                                    <p className="hoursText">{day.openTime} - {day.closeTime}</p>

                                </div>
                                <div className="devider"></div>
                            </div>

                        ))}
                    </div>
                </div>
                <div className="connect">
                    <div className="heading">
                        <CampaignIcon sx={{ color: "#FFD700", height: '30px' }} />
                        <p className="headingText">Connect with us</p>
                    </div>
                    <div className="textContent">
                    <div>
                    <WhatsAppIcon />
                    <p>WhatsApp Us:</p>
                    <a className="footerEmail" href={whatsappUrl} target="_blank" rel="noopener noreferrer">
                    +14794129916
        </a>
                </div>
                        <div>
                            <CalendarMonthIcon />
                            <p className="ConnectText">Reservation:</p>
                            <a className ="footerEmail" href={`tel:${phoneNumber}`} >
                                +14794129916
                            </a>
                        </div>
                        <div>
                            <GroupsIcon />
                            <p>Catering:</p>
                            <a className ="footerEmail" href={`tel:${phoneNumber}`} >
                                +14794129916
                            </a>
                        </div>
                        <div>
                            <EmailIcon />
                            <a href= "mailto: desibitesnwa@gmail.com" className="footerEmail"> desibitesnwa@gmail.com </a>
                        </div>
                        <div>
                            <div className="iconOnConnect">
                                <InstagramIcon sx={{ color: "#FFD700", height: '20px' }} />
                                <FacebookOutlinedIcon sx={{ color: "#FFD700", height: '20px' }} />
                                <LocalTaxiIcon sx={{ color: "#FFD700", height: '20px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="maker">
                <div className="bodyDevider">
                </div>
                <div className="copyRights">
                    <p>
                        &copy; {year} Desi Bites. All rights reserved
                    </p>
                    <p>
                        Developed by <span>Rama krishna komma</span>
                    </p>
                </div>
            </div>
        </div>
    )
};

export default FooterBody;