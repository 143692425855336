import "./Home.css";
import CustomButton from "../../Components/Button/Button";
import foodtruck from "../../Images/foodtruck.jpg";
import homeFood from "../../Images/homefood.jpg";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RestaurantIcon from '@mui/icons-material/Restaurant';

function Home() {
    return (
        <div>
            <div className="homeBanner">
                <div className="homeBackground">
                    <p className="homeBannerText">Fresh, fast, & Delicious</p>
                    <p className="homeBannerSubText">Crafted with the rich, traditional flavors of Indian home-style cooking.</p>
                    <div className="buttonArea">
                        <CustomButton className="vistToday" to='/TruckLocator'>Visit Today</CustomButton>
                        <CustomButton className="viewMenu" to="/menu">View Menu</CustomButton>
                    </div>
                </div>
            </div>
            <div className="homeBodyWrap">
                <div className="homeBody">
                    <div className="imageSide">
                        <img src={foodtruck} alt="foodTruck" className="homeImage" />
                    </div>
                    <div className="textSide">
                        <div className="textHeading">
                            <LocalShippingIcon sx={{ color: "#FFD700" }} fontSize="large" />
                            <p>Serving Since 2024</p>
                        </div>
                        <div className="textBody">
                            <p>Since its debut in 2024, Desi Bites has been transforming ordinary streets into vibrant, flavorful destinations, serving authentic Indian cuisine that captivates the senses and warms the soul.</p>
                            <CustomButton className="truckLocator" to='/TruckLocator'>Truck Locator</CustomButton>
                        </div>
                    </div>
                </div>
                <div className="homeBody">
                    <div className="textSide">
                        <div className="textHeading">
                            <RestaurantIcon sx={{ color: "#FFD700" }} fontSize="large" />
                            <p>Home Food</p>
                        </div>
                        <div className="textBody">
                            <p>At Desi Bites, we take pride in serving authentic home-style dishes, crafted with fresh ingredients and infused with the rich, traditional flavors of India.</p>
                            <CustomButton className="truckLocator" to="/menu">View Menu</CustomButton>
                        </div>
                    </div>
                    <div className="imageSide">
                        <img src={homeFood} alt="homeFood" className="homeImage" />
                    </div>
                </div>
            </div>
            <div className="photoGallery"></div>
        </div>
    );
}

export default Home;
