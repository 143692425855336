export const openingHours = [
    {
        day: 'Monday',
        openTime: "11 am to 2 pm",
        closeTime: '5 am to 10 pm',
    },
    {
        day: 'Tuesday',
        openTime: "-",
        closeTime: '-',
    },
    {
        day: 'Wednesday',
        openTime: "11 am to 2 pm",
        closeTime: '5 am to 10 pm',
    },
    {
        day: 'Thursday',
        openTime: "11 am to 2 pm",
        closeTime: '5 am to 10 pm',
    },
    {
        day: 'Friday',
        openTime: "11 am to 2 pm",
        closeTime: '5 am to 10 pm',
    },
    {
        day: 'Saturday',
        openTime: "10 am to 3 pm",
        closeTime: '5 am to 10 pm',
    },
    {
        day: 'Sunday',
        openTime: "10 am to 3 pm",
        closeTime: '5 am to 10 pm',
    }
]