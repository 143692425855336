function OrderPickup() {
    return (
        <div>
            <p>
                orderPickup
        </p>
        </div>
    )
};

export default OrderPickup;
