
import './Special.css'
function Special() {

    const everyDay = [
        { name: "Veg Delight (Pulavu + Kurma)", price: "$10.99" },
        { name: "Non-Veg Delight (Pulavu + Chicken)", price: "$11.99" },
    ];
    const weekendCombos = [
        { name: "Combo 1", item: "Idly(1) + Poori(1) + Bonda(2)", price: "$8.99" },
        { name: "Combo 2", item: "Idly(1) + Poori(1) + pongal/Upma", price: "$9.99" },
        { name: "Combo 3", item: "Idly(1) + Gare(1) + pongal/Upma", price: "$8.99" },
        { name: "Combo 3", item: "Upma + Pongal", price: "$7.99" },
    ];
    const weekendSpecial = [
        { name: "Hot Pongal/Upma", price: "$7.99" },
        { name: "Upma Pesarattu", price: "$9.99" },
        { name: "Poori With Chole", price: "$10.99" },
        { name: "Pulk/Chapati With Chole", price: "$10.99" },
    ];
    return (
        <div className="menu">
            <div className="menu-section">
                <p className="item-heading">Every Day Specials</p>
                <ul>
                    {everyDay.map((item, index) => (
                        <li key={index}>
                            <span className="item-name">{item.name}</span>
                            <span className="dotted-line"></span>
                            <span className="item-price">{item.price}</span>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="menu-section">
                <p className="item-heading">Weekend Combo's</p>
                <ul>
                    {weekendCombos.map((item, index) => (
                        <li key={index}>
                            <span className="item-name">{item.name}</span>
                            <span className="dotted-line"></span>
                            <span className="item-description">{item.item}</span>
                            <span className="item-price">{item.price}</span>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="menu-section">
                <p className="item-heading">Weekend Specials</p>
                <ul>
                    {weekendSpecial.map((item, index) => (
                        <li key={index}>
                            <span className="item-name">{item.name}</span>
                            <span className="dotted-line"></span>
                            <span className="item-price">{item.price}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
};

export default Special;