import React from 'react';
import Map from '../../Components/Map/Map';
import './TruckLocator.css';

function TruckLocator() {
    const locations = [
        { lat: 36.3729, lng: -94.2088, name: 'Bentonville Downtown', address: 'Downtown Bentonville, AR', timing: '10:00 AM - 9:00 PM' },
        { lat: 36.3600, lng: -94.2847, name: 'Desi Bites', address: 'Davitt Electric, 226 Main St, Centerton, AR 72719', timing: '11:00 AM - 8:00 PM' },
    ];

    const Address = [
        {  name: 'Desi Bites',landMark:'Centerton Dowtown', address: 'Davitt Electric, 226 Main St, Centerton, AR 72719', timing: '11:00 AM - 8:00 PM'}
    ]

    const truckAddress = "226 Main St, Centerton, AR 72719";
    const mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(truckAddress)}`;

    return (
        <div>
            <div className="locationbanner">
                <p className="menuBannerText">Truck Locator</p>
            </div>
            <div className='mapArea'>
                <Map locations={locations} />
            </div>
            <div className='cardList'>
                {Address.map((Address, index) => (
                    <div key={index} className="card">
                        <p className='businessName'>{Address.name}</p>
                        <p className='landMark'>{Address.landMark}</p>
                        <p className='timing'>Timing: {Address.timing}</p>
                        <p className='address'>Location: <a href={mapUrl}>{Address.address} </a></p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TruckLocator;
