import "./Contact.css";
import Catering from "../../Images/catering.jpg";

function Contact() {
    const phoneNumber = "+14794129916";
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent("Hello, I would like to know more about your services")}`;
    return (
        <div className="contactContainer">
            <div className="contactbanner">
                <p className="menuBannerText">Contact</p>
            </div>
            <div className="content">
                <p className="sectionTitle">Catering</p>
                <div className="textContainer">
                    <p>
                        Offering authentic Indian homely food, freshly cooked and perfect for catering your special events. For inquiries or to place an order, please call us at <a href={`tel:${phoneNumber}`} className='contactNumber'>
                        4794129916
                    </a> or what's app <a className="contactNumber" href={whatsappUrl} target="_blank" rel="noopener noreferrer">
                    +14794129916
        </a>
                         or email us at <a href= "mailto: desibitesnwa@gmail.com"> desibitesnwa@gmail.com</a>. We look forward to bringing Desi bites to your table!
                    </p>
                </div>
                <div className="imageContainer">
                    <img src={Catering} alt='foodTruck' className="cateringImage" />
                </div>
            </div>
        </div>
    );
}

export default Contact;
