import "./Footer.css";
import FooterHead from "../../SubComponents/FooterHead/FooterHead";
import FooterBody from "../../SubComponents/FooterBody/FooterBody";

function Footer() {
    return (
        <div className="FooterBody">
            <FooterHead />
            <FooterBody />
        </div>
    )
};

export default Footer;
