import { useState, useEffect } from "react";
import "./FooterHead.css";
import yellow from "../../Images/yellow.jpg";
import CustomButton from '../../Components/Button/Button';

function FooterHead() {
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [showErrMsg, setShowErrMsg] = useState(false);

    const phoneNumber = "+14794129916"; // Replace with your number
    const message = "Hello, I would like to know more about your services";
    
    const openWhatsApp = () => {
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, "_blank");
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowErrMsg(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, [showErrMsg]);

    const handleSignUp = () => {
        if (isValidEmail) {
            console.log("Email is valid:", email);
            setEmail('');
        } else {
            console.log("Invalid email:", email);
            setShowErrMsg(true);
            setEmail('');
        }
    };

    return (
        <div className='Head'>
            <img src={yellow} alt="left" className="HeadImage" />
            <div className="HeadBody">
                <p className="news">Updates</p>
                <p className="subscribe">Got questions or need more info? Reach us directly on WhatsApp for quick and easy communication. We're here to help!</p>
                <div className="emailSide">
                    <CustomButton onClick={openWhatsApp} className='whatsappButton'>
                        Message us on WhatsApp
                    </CustomButton>
                </div>
            </div>
        </div>
    );
}

export default FooterHead;
