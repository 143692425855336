import React, { useState, useEffect } from 'react';
import './PrimaryHeader.css';
import logo from "../../Images/logo.jpeg";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';

function PrimaryHeader() {
    const address = "226 Main St, Centerton, AR 72719";
    const mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    const phoneNumber = "+14794129916";
    const [currentIndex, setCurrentIndex] = useState(0);
    const hours = [
        "Weekdays: 11:00 PM - 10:00 PM",
        "Weekends: 9:00 AM - 9:00 PM",
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % hours.length);
        }, 4000);

        return () => clearInterval(intervalId);
    }, [hours.length]);

    return (
        <div className="headerBody">
            <div className='logoDiv'>
                <img src={logo} className='logo' alt='Logo' />
                <p className='logoText'>
                    Desi Bites
                </p>
            </div>
            <div className='location'>
                <LocationOnIcon className="icon" />
                <a className='locationText' href={mapUrl} target="_blank" rel="noopener noreferrer">
                    {address}
                </a>
                <PhoneEnabledIcon className="icon" />
                <a href={`tel:${phoneNumber}`} className='locationText'>
                    {phoneNumber}
                </a>
                <AirportShuttleIcon className="icon" />
                <div className="slidingHoursContainer">
                    <p className="slidingHoursText">{hours[currentIndex]}</p>
                </div>
            </div>
            <div className="iconArea">
                <a href="https://www.instagram.com/desibitesnwa?igsh=MWdyYTFmNDV6d2V3ZA==" target="_blank" rel="noopener noreferrer">
                    <InstagramIcon className="icon" />
                </a>
                <FacebookOutlinedIcon className="icon" />
                <LocalTaxiIcon className="icon" />
            </div>
        </div>
    )
}

export default PrimaryHeader;
