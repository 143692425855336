import "./Tiffins.css";

function Tiffins() {
  const idliItems = [
    { name: "Idli (3 pieces)", price: "$6.99" },
    { name: "Chitti Idly (10 pieces)", price: "$7.49" },
    { name: "Ghee Idly", price: "$7.49" },
    { name: "Ghee Chitti Idly", price: "$7.99" },
    { name: "Sambar Idly(2 Pieces)", price: "$8.49" },
    { name: "Sambar Chitti Idly", price: "$8.99" },
    { name: "Karampodi/Podi Idly", price: "$7.49" },
    { name: "Karampodi/Podi Chitti Idly", price: "$7.99" },
    { name: "Ghee Karampodi/Podi Idly", price: "$7.99" },
    { name: "Ghee Karampodi/Podi Chitti Idly", price: "$8.49" },
  ];

  const dosaItems = [
    { name: "Plain", price: "$6.99" },
    { name: "Onion", price: "$7.99" },
    { name: "Masala", price: "$8.49" },
    { name: "Egg (Double Egg-1$ extra)", price: "$8.99" },
    { name: "Karam/Karampodi", price: "$7.99" },
    { name: "Onion Karam/Karampodi", price: "$8.49" },
    { name: "Onion Masala", price: "$8.99" },
    { name: "Ghee Roast", price: "$7.49" },
    { name: "Ghee Roast Onion", price: "$8.49" },
    { name: "Ghee Masala", price: "$8.99" },
    { name: "Ghee Karam/Karampodi", price: "$8.49" },
    { name: "Ghee Karam/Karampodi Onion", price: "$8.99" },
    { name: "Ghee Karam/Karampodi Masala", price: "$9.49" },
    { name: "Ghee Karam/Karampodi Onion Masala", price: "$9.99" },
    { name: "Ghee Onion Masala", price: "$9.49" },
    { name: "Pesarattu", price: "$7.99" },
    { name: "Onion Pesarattu", price: "$8.49" },
    { name: "Masala Pesarattu", price: "$9.49" },
    { name: "Onion Masala Pesarattu", price: "$9.99" },
    { name: "Chocolate", price: "$7.99" },
    { name: "Dosa with Chicken", price: "$11.99" },
  ];

  const breadItems = [
    { name: "Poori(2 Pieces)", price: "$8.99" },
    { name: "Poori With Kurma", price: "$10.99" },
    { name: "Poori With Chicken", price: "$12.99" },
    { name: "Chapati/Pulka (with Kurma)", price: "$9.99" },
    { name: "Chapati/Pulka (with Chicken)", price: "$10.99" },
    { name: "Chapati/Pulka (with Tomato Curry)", price: "$8.99" },
  ];

  const streetItems = [
    { name: "Punugulu (8 Pieces)", price: "$6.99" },
    { name: "Chitti Punugulu (14 Pieces)", price: "$7.99" },
    { name: "Mysore Bonda (5 Pieces)", price: "$7.99" },
    { name: "Onion Samosa (5 Pieces)", price: "$4.99" },
    { name: "Potato Samosa (5 Pieces)", price: "$5.99" },
    { name: "Pakodi", price: "$5.99" },
    { name: "Mirchi Bajji (3 Pieces)", price: "$5.99" },
    { name: "Stuffed Mirchi Bajji (2 Pieces)", price: "$5.99" },
    { name: "Cut Mirchi Bajji (10 Pieces)", price: "$7.99" },
    { name: "Garelu (3 Pieces)", price: "$7.99" },
    { name: "Garelu with Chicken", price: "$10.99" },
    { name: "Vada (2 Pieces)", price: "$7.99" },
    { name: "Sambar Vada", price: "$8.99" },
  ];

  const comboItems = [
    { name: "Dosa + 2 Idly", price: "$10.99" },
  ];

  const wrapsItems = [
    { name: "Chicken Wraps", price: "$6.00" },
    { name: "Paneer Wraps", price: "$6.50" },
  ];

  return (
    <div className="menu">
      <div className="menu-section">
        <p className="item-heading">Idli Corner</p>
        <ul>
          {idliItems.map((item, index) => (
            <li key={index}>
              <span className="item-name">{item.name}</span>
              <span className="dotted-line"></span>
              <span className="item-price">{item.price}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="menu-section">
        <p className="item-heading">Dosa Corner</p>
        <ul>
          {dosaItems.map((item, index) => (
            <li key={index}>
              <span className="item-name">{item.name}</span>
              <span className="dotted-line"></span>
              <span className="item-price">{item.price}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="menu-section">
        <p className="item-heading" >Bread Corner</p>
        <ul>
          {breadItems.map((item, index) => (
            <li key={index}>
              <span className="item-name">{item.name}</span>
              <span className="dotted-line"></span>
              <span className="item-price">{item.price}</span>
            </li>
          ))}
        </ul>
        <p>Chapati(2 Pieces), Pulka(3 Pieces)</p>
      </div>
      <div className="menu-section">
        <p className="item-heading" >Street Bites</p>
        <ul>
          {streetItems.map((item, index) => (
            <li key={index}>
              <span className="item-name">{item.name}</span>
              <span className="dotted-line"></span>
              <span className="item-price">{item.price}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="menu-section">
        <p className="item-heading" >Daily Combo</p>
        <ul>
          {comboItems.map((item, index) => (
            <li key={index}>
              <span className="item-name">{item.name}</span>
              <span className="dotted-line"></span>
              <span className="item-price">{item.price}</span>
            </li>
          ))}
        </ul>
      </div>
      {/* <div className="menu-section">
        <p className="item-heading" >Wraps</p>
        <ul>
          {wrapsItems.map((item, index) => (
            <li key={index}>
              <span className="item-name">{item.name}</span>
              <span className="dotted-line"></span>
              <span className="item-price">{item.price}</span>
            </li>
          ))}
        </ul>
      </div>*/}
    </div>
  );
}

export default Tiffins;
