import React from 'react';
import './MenuNavBar.css';
import CustomButton from '../Button/Button';

function MenuNavBar({ handleMenuItemClick, selectedMenuItem }) {
    return (
        <div className="menuNavBar">
            <CustomButton
                className={`menuBarItem ${selectedMenuItem === 'Tiffins' ? 'menuBarItemSelected' : ''}`}
                onClick={() => handleMenuItemClick('Tiffins')}
            >
                Tiffins
            </CustomButton>
            <CustomButton
                className={`menuBarItem ${selectedMenuItem === 'Special' ? 'menuBarItemSelected' : ''}`}
                onClick={() => handleMenuItemClick('Special')}
            >
            Signature Meals
            </CustomButton>
           {/* <CustomButton
                className={`menuBarItem ${selectedMenuItem === 'VegThali' ? 'menuBarItemSelected' : ''}`}
                onClick={() => handleMenuItemClick('VegThali')}
            >
                Veg Thali
            </CustomButton>
            
            <CustomButton
                className={`menuBarItem ${selectedMenuItem === 'Pulao' ? 'menuBarItemSelected' : ''}`}
                onClick={() => handleMenuItemClick('Pulao')}
            >
                Pulao
            </CustomButton>*/}
            <CustomButton
                className={`menuBarItem ${selectedMenuItem === 'Beverage' ? 'menuBarItemSelected' : ''}`}
                onClick={() => handleMenuItemClick('Beverage')}
            >
                Beverage
            </CustomButton>
        </div>
    );
}

export default MenuNavBar;
